<template >
    <div>
        <loading style="z-index:1100" :active="isLoading" loader="dots" :height="25" :width="25" color="#083c5d"
        :is-full-page="fullPage"></loading>
    </div>
</template>
<script>
import Loading from 'vue3-loading-overlay'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css'
export default {
    components: {
        loading: Loading
    },
    props: {
        isLoading: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data() {
        return {
            fullPage: true
        }
    }
}
</script>
<style >
    
</style>
